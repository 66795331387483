import "core-js/stable";
import "regenerator-runtime/runtime";
import isMobile from 'ismobilejs';
import Swiper from 'swiper';
import Autoplay from 'swiper/modules/autoplay.mjs';
import EffectFade from 'swiper/modules/effect-fade.mjs';
import FreeMode from 'swiper/modules/free-mode.min.mjs';
import AOS from "aos";

const is_mobile = isMobile().phone || window.innerWidth <= 600;

window.addEventListener('DOMContentLoaded', () => {
  initSlider();
  headerHandle();

  AOS.init({
    once: true,
    mobile: false
  });

  if (!is_mobile) {
    const aosAnimation = document.querySelectorAll('.aos-init');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.intersectionRatio > 0) {
          entry.target.classList.add('aos-animate');
        }
      });
    });
    aosAnimation.forEach(aosObject => {
      observer.observe(aosObject);
    });
  }

});

document.querySelector('main').addEventListener('scroll', () => {
  headerHandle();
});

window.addEventListener('scroll', () => {
  if (is_mobile) {
    headerHandle();
  }
})

const initSlider = () => {
  const slider = new Swiper('.swiper', {
    modules: [Autoplay, EffectFade, FreeMode],
    slidesPerView: is_mobile ? 1 : 'auto',
    spaceBetween: 0,
    //freeMode: true,
    autoplay: {
      delay: is_mobile ? 3000 : 5000,
      disableOnInteraction: false,
    },
    speed: is_mobile ? 3000 : 5000,
    effect: is_mobile ? 'fade' : null,
    fadeEffect: { crossFade: true },
    loop: true
  })

  slider.init();
}

const headerHandle = () => {
  const header = document.querySelector('.main--header');
  const main = document.querySelector('main');
  const topOffset = is_mobile ? window.scrollY : main.scrollTop;

  if (header) {
    if (topOffset > 400) {
      header.classList.add('scrolled');
    }  else {
      header.classList.remove('scrolled');
    }
  }
}
